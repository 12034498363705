import { useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import DropdownAccordion from 'src/legacy/components/common/dropdown-accordion';
import BodyText from 'src/legacy/components/common/text/body-text';
import { SelectAccordionProps } from '..';

const SelectAccordionA: React.FC<SelectAccordionProps> = ({ trigger, items, onSelect, contentMaxHeight }) => {
    const { theme } = useHarmony();
    const { isOpen, onClose, onToggle } = useDisclosure();

    return (
        <DropdownAccordion open={isOpen} onToggle={onToggle} trigger={trigger} contentMaxHeight={contentMaxHeight}>
            {items.map((item, i) => (
                <PaddedListItem
                    key={i}
                    theme={theme}
                    role="button"
                    onClick={() => {
                        onSelect(i);
                        onClose();
                    }}
                >
                    <StyledBodyText size={'14px'} padding={'11px 16px'} color={theme.gray[800]}>
                        {item}
                    </StyledBodyText>
                </PaddedListItem>
            ))}
        </DropdownAccordion>
    );
};

export default SelectAccordionA;

const PaddedListItem = styled.li<IThemeProps>`
    cursor: pointer;
    height: 40px;
`;

const StyledBodyText = styled(BodyText)<{ padding: string }>`
    ${({ padding }) =>
        `
            display: inline-block;
            padding: ${padding};
        `}
`;
