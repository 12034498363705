import { useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import DrawerHeader from '@legacy/designs/drawer-header';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import CustomDrawer from 'src/legacy/components/common/custom-drawer';
import DropdownAccordion from 'src/legacy/components/common/dropdown-accordion';
import BodyText from 'src/legacy/components/common/text/body-text';
import { SelectAccordionProps } from '..';

const SelectAccordionC: React.FC<SelectAccordionProps> = ({ trigger, items, onSelect, contentMaxHeight, placeholder = '' }) => {
    const { theme } = useHarmony();
    const { isOpen, onClose, onToggle } = useDisclosure();

    return (
        <>
            <DropdownAccordion open={false} onToggle={onToggle} trigger={trigger} contentMaxHeight={contentMaxHeight} />
            <CustomDrawer open={isOpen} onClose={onClose} anchor="bottom" radius="20px 20px 0 0" zIndex={ThemeUtils.zIndex.drawer}>
                <DrawerHeader title={placeholder} headerButton={{ type: 'close', onClose }} hasDivider={false} />
                <OptionList>
                    {items.map((item, i) => (
                        <OptionListItem
                            key={i}
                            theme={theme}
                            role="button"
                            onClick={() => {
                                onSelect(i);
                                onClose();
                            }}
                        >
                            <BodyText size={'17px'} color={theme.gray[800]}>
                                {item}
                            </BodyText>
                        </OptionListItem>
                    ))}
                </OptionList>
            </CustomDrawer>
        </>
    );
};

export default SelectAccordionC;

const OptionList = styled.ul`
    max-height: 310px;
    overflow-y: scroll;
    padding-bottom: 24px;
`;

const OptionListItem = styled.li<IThemeProps>`
    cursor: pointer;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
