import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import { DropdownAccordionProps } from '@legacy/components/common/dropdown-accordion';
import SelectAccordionA from './designA/select-accordion';
import SelectAccordionB from './designB/select-accordion';
import SelectAccordionC from './designC/select-accordion';

export interface SelectAccordionProps extends DropdownAccordionProps {
    items: string[];
    onSelect: (index: number) => void;
    placeholder?: string;
}

const SelectAccordion: React.FC<SelectAccordionProps> = (props) => {
    const Component = useDesignComponents<typeof SelectAccordionA | typeof SelectAccordionA>({
        [Design.DesignA]: SelectAccordionA,
        [Design.DesignB]: SelectAccordionB,
        [Design.DesignC]: SelectAccordionC,
    });

    return <Component {...props} />;
};

export default SelectAccordion;
